import React from "react";
import AnimatedText from "./animated-text";

import styles from "./card.module.scss";

const startAnimation = () => {
    document.body.classList.add("__animating");
};

const Card = () => {
    return (
        <div className={styles.container} onClick={startAnimation}>
            <div className={styles.cover} />
            <AnimatedText 
                lines={[
                    "To Suzi",
                    "Even with an actual pandemic happening it's been a great year with you",
                    "Happy valentines day",
                    "Love from Jacek    xxx  <3",
                ]}
            />
        </div>
    );
};

export default Card;
