import React from "react";

import styles from "./animated-text.module.scss";

let letterDelay;

const wrapLine = (line) => (
    <div>
        {line.split(" ")
             .map((word) => (
                <span className={styles.word}>
                    {word.split("").map((letter) => {
                        letterDelay += 80;

                        console.log(letterDelay);

                        return (
                            <span
                                className={styles.letter}
                                style={{ animationDelay: `${letterDelay}ms` }}
                            >
                                {letter}
                            </span>
                        );
                    })}
                </span>
             ))
             .reduce(
                 (carry, value) => carry.concat(value, " "),
                 [" "]
            )
        }
    </div>
);

const AnimatedText = ({ lines }) => {
    letterDelay = 750;

    return (
        <div className={styles.container}>
            {lines.map(wrapLine)}
        </div>
    );
};

export default AnimatedText;
