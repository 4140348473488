import React from "react";
import Card from "./card";

import styles from "./app.module.scss";

const App = () => {
    return (
        <div className={styles.wrapper}>
            <Card />
        </div>
    );
};

export default App;
